import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import gql from "graphql-tag";

const query = gql`
  query case($id: ID!) {
    case: publicCase(id: $id){
      id
      caseNo
      incident
      defendants {
        id
        name
        age
        charges {
          id
          verdict
          plea
          charge
        }
      }
      description
      tags
      trialDateFrom
      trialDateTo
      trialLength
      verdictDate
      sentenceDate
      magistrate
      prosecutionRep
      defendantRep
      summary
      verdict
      verdictReason
      sentence
      sentenceReason
      bailment
      bailmentRemarks
      bailmentCondition
      remarks
      internalRemarks
      charges
      custodyLength
      appeal
      appealJudgementUrl
      bailmentAppeal
      complaints
      publicPerception
      mediaUrls
      assigneeId
      editStatus
      references {
        nodes {
          id
          content
          url
          source
          datetime
        }
      }
    }
  }
`;


export default ({ id } = {}) => {
  const { data, loading } = useQuery(query, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })
  return { data: _.get(data, 'case', {}), loading };

}
