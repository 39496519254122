import styles from './index.module.scss';

function Indexpage() {
  return (
    <div className={styles.root}>
      <h1>《香港裁判法院示威案件判例匯編》引言</h1>
      <p>
        自2019年6月，香港法庭突然須要處理大量社運相關的刑事案件，司法機構以及本地法律界均忙得不可開交。根據最新數字，現時在過萬名因社會事件的被捕人士當中，有超過2500人被檢控<sup>1</sup>，其中已排期審訊的案件裏，最遲開審的日期為2023年12月尾，案件預計2024年2月審結。
      </p>
      <p>
        除了比較嚴重的控罪，如暴動、爆炸品相關控罪、嚴重傷人控罪等，會被轉介至高等法院及區域法院之外，大部分案件均會在裁判法院完成司法程序。但是，只有區域法院以上的案件才會有供公眾參閱的裁決書上載至司法機構網頁，裁判法院的判決鮮有如此完整的正式紀錄，最多也只是一小部分案件有被選輯放到網上，當中部分更只是法庭數碼錄音的口頭謄本。<sup>2</sup>
      </p>
      <p>
      有見及此，我們發起了《香港裁判法院示威案件判例匯編》(“Compendium Project”)，目的為這些裁判法院案件做詳細記錄。這些案件徹底改變了香港司法歷史以及本地歷史，更重要的是不論結果，這些案件也改變了所有被告，甚至其身邊人的一生。我們認為裁判法院作為所有案件開始的法院，其作出的裁決與高級法院的同樣重要。除了歷史紀錄外，我們希望Compendium Project 能夠為傳媒朋友及學術研究提供資料，為相關報道及學術鑽研出一分力。
      </p>
      <p>
      現時，我們整合了接近500宗案件，當中處理最多控罪的法院為東區裁判法院，有209條，而最多記錄的控罪為「刑事損／毀壞」，有140條控罪，其次為「管有攻擊性武器」，有138條控罪。當中有257人認罪、有過百人就358條控罪接受審訊、有260條控罪判處監禁、有106條控罪判處勞教中心、更生中心、教導所等刑罰。我們所整合的數據亦包括主理裁判官、申請上訴期間保釋、審訊長度、還押日數等數據及資料。
      </p>
      <h1>編輯如何撰寫 Compendium Project 的案件摘要？</h1>
      <p>
        Compendium Project 的編輯在撰寫案件摘要 (Case Digest) 時遵循兩大原則：確保資料客觀準確以及保障當事人個人私隱。
      </p>
      <p>
        編輯在撰寫摘要時，除了參考司法機構的公開資料（包括裁決書及對公眾投訴的回應）以及《法庭文字直播台》的審訊直播紀錄外，亦會對照不同媒體的相關報道，綜合不同資料來源寫成摘要，務求內容準確。基於保障個人私隱的考慮，編輯會避免輸入敏感的個人資料，以免案件當事人遭到「起底」，案件編號及當事人的全名也只會供編輯內部參考核實之用，不作公開。
      </p>
      <p>
        撰文編輯完成案件摘要初稿後，會先交予校對編輯審核，審核後 Compendium Project 會盡可能邀請案件相關的律師過目，旨在核實已整理的公開資料是否準確<sup>3</sup>。
      </p>

      <div className="py-4">
        <p>
          <sup className="mr-2">1</sup>
          <a target="_blank" rel="noreferrer noopener" href="https://news.rthk.hk/rthk/ch/component/k2/1584824-20210408.htm">
            反修例事件2521人涉司法程序 614人被定罪 - RTHK
          </a>
        </p>
        <p>
          <sup className="mr-2">2</sup>
          <a target="_blank" rel="noreferrer noopener" href="https://www.hklii.hk/cgi-bin/sinodisp/chi/hk/cases/hkmagc/2020/5.html?stem=&synonyms=&query=(%E4%BD%95%E4%BF%8A%E5%A0%AF%20)%20OR%20ncotherjcitationtitles(%E4%BD%95%E4%BF%8A%E5%A0%AF%20">
          香港特別行政區 訴 王愷銘 [2020] HKMagC 5; ESCC 654/2020 (10 June 2020) (hklii.hk)
          </a>
        </p>
        <p>
          <sup className="mr-2">3</sup>

            參與核實的律師團隊會確保遵循對當事人的保密原則，不會對公開領域以外的資料作出任何評論。
        </p>
      </div>
    </div>
  );
}

export default Indexpage;
