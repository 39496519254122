import classnames from 'classnames';
import moment from 'moment';
import styles from './index.module.scss';

const Reference = ({ data }) => {
  return (
    <div className={classnames(styles.root)}>
      <a className={styles.source} href={data} target="_blank">{data}</a>
    </div>
  )
};
export default Reference;
