import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import gql from "graphql-tag";

const query = gql`
  query cases(
    $first: Int,
    $tags: [String],
    $offset: Int,
    $keyword: String,
    $charge: String,

    $magistrate: String, $from: DateTime, $to: DateTime, $age: Int, $name: String, $incident: String
  ) {
    cases: publicCases(
      charge: $charge,
      connectionArgs: { first: $first, offset: $offset },
      tags: $tags,
      keyword: $keyword,
      magistrate: $magistrate, incident: $incident,
      name: $name, age: $age,
      from: $from, to: $to,
    ){
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalCount
      }
      nodes{
        id
        incident
        summary
        id
        caseNo
        editStatus
        verdictDate
        sentenceDate
        trialDateFrom
        trialDateTo
        defendants {
          id
          name
          age
          charges {
            id
            verdict
            plea
            charge
          }
        }
        isVirtual
        summary
        tags
        magistrateProfile {
          id
          name
          avatar
        }
      }
    }
  }
`;


export default ({ query: queryParams } = {}) => {
  const relatedIds = queryParams.tags && queryParams.tags.filter(v => v.match(/case-[0-9]+/)).map(v => v.replace('case-', ''));
  const { data, loading, fetchMore } = useQuery(query, {
    variables: {
      first: 20,
      ..._.pick(queryParams, ['keyword', 'from', 'magistrate', 'name', 'to', 'incident', 'charge']),
      ...queryParams.age && { age: parseInt(queryParams.age) },
      ...queryParams.tags && { tags: queryParams.tags.filter(v => !v.match(/case-[0-9]+/))},
      ...relatedIds && relatedIds.length && { relatedIds },
    },
    fetchPolicy: 'network-only',
  })
  const loadMore = (page) => fetchMore({
    variables: {
      first: 20,
      offset: page * 20,
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      console.log(fetchMoreResult);
      const { nodes } = fetchMoreResult.cases;
      const { nodes: nodesO } = previousResult.cases;

      if (nodes.length) {
        return Object.assign({}, fetchMoreResult, {
          cases: {
            ...fetchMoreResult.cases,
            nodes: [...nodesO, ...nodes]
          }
        });
      }
      return previousResult;
    }
  });
  return {
    data: _.get(data, 'cases.nodes', []),
    loading, loadMore,
    hasNextPage: _.get(data, 'cases.pageInfo.hasNextPage'),
    totalCount: _.get(data, 'cases.pageInfo.totalCount'),
  };

}
